import React, { Component } from "react";
//import ReactDOM from 'react-dom';
// import './App.css';
//import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Route, Link, BrowserRouter as Router, Switch } from "react-router-dom";
import MetaData from "./components/Sitewide/MetaData";
import MobileMenu from "./components/MobileMenu/mobileMenu";
import Header from "./components/Header/header.jsx";

import Contact from "./components/ContactSection/contact";
import Footer from "./components/Footer/footer";
import Loader from "./components/Sitewide/loader";
import axios from "axios";

import Homepage from "./Pages/homepage";
import TermsConditions from "./Pages/terms-conditions";
import PrivacyPolicy from "./Pages/privacy-policy";
import NotFoundPage from "./Pages/404";

import ScrollableAnchor from "react-scrollable-anchor";
import { configureAnchors } from "react-scrollable-anchor";
configureAnchors({ offset: 20, scrollDuration: 800 });

//console.log(json);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: [],
      isToggleOn: false
    };
  }

  toggleShowModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var getFrom = "";
    if (window.location.host === "localhost:3000") {
      getFrom =
        "https://www.stevefurley.co.uk/wordpress_site/wp-json/wp/v2/pages/121";
    } else {
      getFrom =
        "https://www.stevefurley.co.uk/distribution/json_for_page_121.json";
    }
    axios.get(getFrom).then(response => {
      this.setState(() => {
        return {
          data: response.data,
          loading: false
        };
      });
    });
  }

  render() {
    const { loading, data } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <div>
        <Router>
          <MetaData jsonData={this.state.data} />

          <MobileMenu
            jsonData={this.state.data}
            toggleShowModalCallback={this.toggleShowModal.bind(this)}
            openMenu={this.state.showModal ? true : false}
          />

          <div className={!this.state.showModal ? "" : " open"}>
            <Header
              jsonData={this.state.data}
              toggleShowModalCallback={this.toggleShowModal.bind(this)}
              openMenu={this.state.showModal ? true : false}
            />
            <Switch>
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route exact path="/" component={Homepage} />
              <Route path="/wordpress_site" />
              <Route path="/wordpress_site/" />
              <Route component={NotFoundPage} />
            </Switch>

            <footer>
              <ScrollableAnchor id={"contact"}>
                <Contact jsonData={this.state.data} />
              </ScrollableAnchor>
              <Footer jsonData={this.state.data} />
            </footer>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;

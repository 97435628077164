import React, { Component } from "react";
import { Helmet } from "react-helmet";

class MetaData extends Component {
  render() {
    const json = this.props.jsonData;
    const yoast_description = json.yoast_description;
    const yoast_title = json.yoast_title;
    return (
      <div>
        <Helmet>
          <title>{yoast_title}</title>
          <meta name="description" content={yoast_description} />
          <meta name="theme-color" content="#2706a0" />
          <link rel="canonical" href="https://www.stevefurley.co.uk/" />
          <meta property="og:locale" content="en_GB" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={yoast_title} />
          <meta property="og:description" content={yoast_description} />
          <meta property="og:url" content="https://www.stevefurley.co.uk/" />
          <meta property="og:site_name" content={yoast_title} />
          <meta
            property="og:image"
            content="https://stevefurley.co.uk/wordpress_site/wp-content/uploads/2019/04/steve-furley-freelance-web-developer.png"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:description" content={yoast_description} />
          <meta name="twitter:title" content={yoast_title} />
        </Helmet>
      </div>
    );
  }
}
export default MetaData;

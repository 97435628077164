import React, { Component } from "react";
class Header extends Component {
  updateParent() {
    this.props.toggleShowModalCallback();
  }

  render() {
    //console.log(this);
    var json = this.props.jsonData;

    const title = json.acf.page_title_set;

    const image = json.acf.header_background_image.sizes.slider_desktop;
    const subtitle = json.acf.page_sub_title_set;
    const left_button_text = json.acf.left_button_text;
    const left_button_link = json.acf.left_button_link;
    const right_button_text = json.acf.right_button_text;
    const right_button_link = json.acf.right_button_link;
    return (
      <header id="header" style={{ backgroundImage: "url(" + image + ")" }}>
        <div className="inner-header">
          <a href="/" className="header-logo">
            <img
              src={json.acf.header_logo.url}
              alt={json.acf.header_logo.alt}
            />
          </a>
          <span className="flexgrow-1" />
          <nav className="header-menu">
            <ul>
              <li>
                <a className="menubutton" href="/#home">
                  Home
                </a>
              </li>
              <li>
                <a className="menubutton" href="/#about-me">
                  about
                </a>
              </li>
              <li>
                <a className="menubutton" href="/#projects">
                  Projects
                </a>
              </li>
              <li>
                <a className="menubutton" href="/#contact">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <div
            id="mobile-menu-button"
            onClick={this.updateParent.bind(this)}
            className={!this.props.openMenu ? "hamburger " : "hamburger open"}
          >
            <span />
          </div>
        </div>
        <div className="container">
          <h1>{title}</h1>
          <h2
            className="description"
            dangerouslySetInnerHTML={{
              __html: subtitle
            }}
          />
          <div className="button-container">
            <a className="red-button" href={left_button_link}>
              <span>{left_button_text}</span>
            </a>
            <span className="or">or</span>
            <a className="red-button" href={right_button_link}>
              <span>{right_button_text}</span>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

import React, { Component } from "react";
import closeIcon from "../../assets/close-icon.svg";

// on component per file is best
class MobileMenu extends Component {
  updateParent() {
    this.props.toggleShowModalCallback();
  }

  render() {
    return (
      <div
        id="mobile-menu"
        className={!this.props.openMenu ? "move " : "move open"}
      >
        <span
          className={
            !this.props.openMenu ? "coloured-overlay" : "coloured-overlay open"
          }
        />
        <nav
          className={!this.props.openMenu ? "mobile-menu" : "mobile-menu open"}
        >
          <img
            onClick={this.updateParent.bind(this)}
            src={closeIcon}
            alt="close icon"
            className="close-menu-button"
          />
          <ul>
            <li>
              <a
                className="menubutton"
                href="#home"
                onClick={this.updateParent.bind(this)}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="menubutton"
                onClick={this.updateParent.bind(this)}
                href="#about-me"
              >
                About
              </a>
            </li>
            <li>
              <a
                className="menubutton"
                onClick={this.updateParent.bind(this)}
                href="#projects"
              >
                Projects
              </a>
            </li>
            <li>
              <a
                className="menubutton"
                onClick={this.updateParent.bind(this)}
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default MobileMenu;

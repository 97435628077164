import React from "react";
import Loader from "../components/Sitewide/loader";
import axios from "axios";
import { Helmet } from "react-helmet";
class PrivacyPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: []
    };
  }

  componentDidMount() {
    var getFrom = "";
    if (window.location.host === "localhost:3000") {
      getFrom =
        "https://www.stevefurley.co.uk/wordpress_site/wp-json/wp/v2/pages/3";
    } else {
      getFrom =
        "https://www.stevefurley.co.uk/distribution/json_for_page_3.json";
    }
    axios.get(getFrom).then(response => {
      this.setState(() => {
        return {
          data: response.data,
          loading: false
        };
      });
    });
  }

  render() {
    const { loading, data } = this.state;
    if (loading) {
      return <Loader />;
    }
    const title = this.state.data.title.rendered;
    const mainContent = this.state.data.content.rendered;
    const yoast_description = this.state.data.yoast_description;
    const yoast_title = this.state.data.yoast_title;
    console.log(this);
    return (
      <div>
        <Helmet>
          <title>{yoast_title}</title>
          <meta name="description" content={yoast_description} />
          <meta name="keywords" cpntent="Privacy Policy" />
        </Helmet>
        <h1
          className="pad-top-50 pad-bottom-20 text-center"
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
        <div
          className="description col-12 col-8-m pad-bottom-50 center text-center"
          dangerouslySetInnerHTML={{
            __html: mainContent
          }}
        />
      </div>
    );
  }
}
export default PrivacyPolicy;

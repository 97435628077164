import React, { Component } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
class AboutMe extends Component {
  render() {
    const json = this.props.jsonData;
    const big_text = json.acf.big_text;
    const big_sub_text_start = json.acf.big_sub_text_start;
    const big_sub_text_end = json.acf.big_sub_text_end;
    const sub_text_icon = json.acf.sub_text_icon.url;
    const im_steve_photo = json.acf.im_steve_photo.url;
    const im_steve_photoAlt = json.acf.im_steve_photo.alt;
    const im_steve_title = json.acf.im_steve_title;
    const im_steve_description = json.acf.im_steve_description;

    return (
      <React.Fragment>
        <ScrollableAnchor id={"about-me"}>
          <section className="hello-section container">
            <p className="hello">{big_text}</p>
            <h3>
              <span className="starttext">{big_sub_text_start}</span>
              <img src={sub_text_icon} alt="heart-icon" />
              <span className="endtext">{big_sub_text_end}</span>
            </h3>
          </section>
        </ScrollableAnchor>

        <section className="about-me" id="about">
          <div className="container relative">
            <article className="leftside">
              <header>
                <h3>{im_steve_title}</h3>
              </header>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: im_steve_description
                }}
              />
              <a className="red-button margin-top-30" href="#contact">
                <span>Get in touch</span>
              </a>
            </article>
            <div
              className="rightside"
              style={{
                backgroundImage: "url(" + im_steve_photo + ")"
              }}
            >
              <img src={im_steve_photo} alt={im_steve_photoAlt} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutMe;

import React, { Component } from "react";

class Testimonials extends Component {
  render() {
    const json = this.props.jsonData;

    const testimonials = json.acf.quotes.map((item, i) => (
      <div
        className={
          "pad-bottom-10-m pad-bottom-15 col-12   col-4-l testimonial flex flex-column row-" +
          i
        }
        key={i}
      >
        <div className="inner flex-grow-1">
          <div className="block text-center">
            <span
              className="person"
              style={{
                backgroundImage: "url(" + item.logo.sizes.thumbnail + ")"
              }}
            />
          </div>
          <h4 className=" text-center pad-top-10 uppercase font-600">
            {item.name}
          </h4>
          <a
            href={item.link}
            target="_blank"
            key={i}
            rel="noopener noreferrer"
            className={"custom-links"}
          >
            {item.company_name}
          </a>
          <div className="description">"{item.description}"</div>
        </div>
      </div>
    ));

    return (
      <React.Fragment>
        <section id="testimonials">
          <div className="container no-padding">
            <div className="col-12 col-9-m col-12-l no-padding center flex flex-center flex-wrap">
              {testimonials}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;

import React, { Component } from "react";
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setName: "",
      question: "",
      setEmail: "",
      telephone: "",
      honeyPot: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    //console.log(this);
  }

  handleSubmit(event) {
    // get all data in form and return object

    //var form = document.forms["customContactForm"];

    // function validEmail(email) {
    //   var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    //   return re.test(email);
    // }

    function validateHuman(honeypot) {
      if (honeypot) {
        //if hidden form filled up
        console.log("Robot Detected!");
        return true;
      } else {
        console.log("Welcome Human!");
        var loaderIcon = document.querySelector(".loading-wrapper");
        if (loaderIcon) {
          loaderIcon.style.display = "flex";
        }
      }
    }

    function getFormData(form) {
      var elements = form.elements;
      var returnedEmail = "";
      var fields = Object.keys(elements)
        .filter(function(k) {
          return elements[k].name !== "honeypot";
        })
        .map(function(k) {
          if (elements[k].name !== undefined) {
            returnedEmail = elements[k].name;
            // special case for Edge's html collection
          } else if (elements[k].length > 0) {
            returnedEmail = elements[k].item(0).name;
          }
          return returnedEmail;
        })
        .filter(function(item, pos, self) {
          return self.indexOf(item) === pos && item;
        });

      var formData = {};
      fields.forEach(function(name) {
        var element = elements[name];

        // singular form elements just have one value
        formData[name] = element.value;

        // when our element has multiple items, get their values
        if (element.length) {
          var data = [];
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i);
            if (item.checked || item.selected) {
              data.push(item.value);
            }
          }
          formData[name] = data.join(", ");
        }
      });

      // add form-specific values into the data
      formData.formDataNameOrder = JSON.stringify(fields);
      formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
      formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

      console.log(formData);
      return formData;
    }

    function handleFormSubmit(event) {
      // handles form submit without any jquery
      // we are submitting via xhr below
      var form = event.target;
      var data = getFormData(form); // get the values submitted in the form

      console.log(data.honeyPot);
      if (validateHuman(data.honeyPot)) {
        //if form is filled, form will not be submitted
        return false;
      }

      if (data.setName.length < 1 || data.setEmail.length < 1) {
        if (data.setName.length < 1) {
          document.querySelector(".loading-wrapper").style.display = "none";
          document.querySelector(".name-missing").style.display = "block";
        } else {
          document.querySelector(".name-missing").style.display = "none";
        }
        if (data.setEmail.length < 1) {
          document.querySelector(".loading-wrapper").style.display = "none";
          document.querySelector(".setEmail-missing").style.display = "block";
        } else {
          document.querySelector(".setEmail-missing").style.display = "none";
        }
        if (data.question.length < 1) {
          document.querySelector(".loading-wrapper").style.display = "none";
          document.querySelector(".question-missing").style.display = "block";
        } else {
          document.querySelector(".question-missing").style.display = "none";
        }

        return false;
      } else {
        disableAllButtons(form);
        var url = form.action;
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        // xhr.withCredentials = true;
        xhr.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );
        xhr.onreadystatechange = function() {
          console.log(xhr.status, xhr.statusText);
          console.log(xhr.responseText);
          var formElements = document.querySelector(".form-elements");
          if (formElements) {
            formElements.style.display = "none"; // hide form
          }
          var loaderIcon = document.querySelector(".loading-wrapper");
          if (loaderIcon) {
            loaderIcon.style.display = "none";
          }
          var thankYouMessage = document.querySelector(".thankyou_message");
          if (thankYouMessage) {
            thankYouMessage.style.display = "block";
          }
          return;
        };

        // url encode form data for sending as post data
        var encoded = Object.keys(data)
          .map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
          })
          .join("&");
        xhr.send(encoded);
      }
    }

    // function loaded() {
    //   console.log("Contact form submission handler loaded successfully.");
    //   // bind to the submit event of our form
    //   var forms = document.querySelectorAll("form.gform");
    //   for (var i = 0; i < forms.length; i++) {
    //     forms[i].addEventListener("submit", handleFormSubmit, false);
    //   }
    // }
    // document.addEventListener("DOMContentLoaded", loaded, false);

    function disableAllButtons(form) {
      var buttons = form.querySelectorAll("button");
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
      }
    }

    handleFormSubmit(event);
    //loaded();

    event.preventDefault();
  }

  render() {
    return (
      <div className="custom-form-wrapper">
        <form
          className="gform pure-form pure-form-stacked form-elements"
          name="customContactForm"
          onSubmit={this.handleSubmit}
          method="POST"
          action="https://script.google.com/macros/s/AKfycby3cYCmhNg_ZqBz8lC4TMuGq9D4O6G-h2_EOlKoSG_FyvrVgakA/exec"
        >
          <div className="input-wrapper pad-bottom-20">
            <input
              name="setName"
              type="text"
              placeholder="Name"
              aria-label="Full Name"
              value={this.state.setName}
              onChange={this.handleInputChange}
            />
            <span className="name-missing none error-message">
              Please include your name
            </span>
          </div>

          <div className="input-wrapper pad-bottom-20">
            <input
              name="telephone"
              type="text"
              aria-label="Telephone Number"
              placeholder="Telephone"
              value={this.state.telephone}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="input-wrapper pad-bottom-20">
            <input
              name="setEmail"
              type="email"
              aria-label="Email address"
              placeholder="Email"
              value={this.state.setEmail}
              onChange={this.handleInputChange}
            />
            <span className="setEmail-missing none error-message">
              Please include your email address
            </span>
          </div>

          <div className="input-wrapper pad-bottom-20">
            <textarea
              name="question"
              aria-label="Please ask your question here"
              value={this.state.question}
              onChange={this.handleInputChange}
              placeholder="Message"
            />
            <span className="question-missing none error-message">
              Please include a message
            </span>
          </div>
          <div className="input-wrapper pad-bottom-20">
            <span className="question-missing none error-message">
              Please tick this box if you would like to proceed.
            </span>
          </div>
          <label className="sr-only none">Keep this field blank</label>
          <input
            className="none"
            id="honeypot"
            name="honeyPot"
            type="text"
            value={this.state.honeyPot}
            onChange={this.handleInputChange}
          />
          <input type="submit" className="send-button" value="Send" />
        </form>
        <div className="loading-wrapper">
          <div className="loader" />
          <p>Sending message...</p>
        </div>

        <div className="thankyou_message none">
          <p className="maintext">Thanks for contacting me!</p>
          <p className="smaller-text">I will get back to you very soon!</p>
        </div>
      </div>
    );
  }
}

export default ContactForm; // Don’t forget to use export default!

import React, { Component } from "react";
import ContactForm from "./contactForm"; // Import a component from another file

// on component per file is best
class Contact extends Component {
  render() {
    var json = this.props.jsonData;
    return (
      <section id="contact-section" className="overflow">
        <div className="container no-padding flex flex-wrap flex-space-around">
          <div className="col-12 col-5-m">
            <h3 className="fs-2 font-600 title ">
              {json.acf.get_in_touch_title}
            </h3>
            <a
              className="block email-footer"
              href={"mailto:" + json.acf.email_address_set}
            >
              {json.acf.email_address_set}
            </a>
            <a
              className="block phone-footer"
              href={"tel:" + json.acf.contact_number_set}
            >
              {json.acf.contact_number_set}
            </a>
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: json.acf.contact_text_section
              }}
            />
          </div>
          <div className="col-12 col-6-m col-5-l  contact-form relative pad-top-15 pad-bottom-15">
            <ContactForm />
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;

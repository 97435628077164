import React, { Component } from "react";
import LazyLoad from "react-lazy-load";
class SkillsUsed extends Component {
  render() {
    const json = this.props.jsonData;

    const skills = json.acf.built_using.map((item, i) => (
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={item.icon.alt}
        key={i}
      >
        <div className="pad-bottom-10-m pad-bottom-15">
          <LazyLoad debounce={false} offsetVertical={500}>
            <img src={item.icon.url} alt={item.icon.alt} />
          </LazyLoad>
        </div>
      </a>
    ));

    return (
      <React.Fragment>
        <section id="skill-used">
          <div className="container flex-space-around">
            <div className="col-12 col-5-l leftside ">
              <h2 className="title">Skills used</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: json.acf.email_description
                }}
              />
            </div>
            <div className='"col-12 col-5-l'>
              <div className="flex flex-grow-1 skills-wrapper flex-wrap">
                {skills}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SkillsUsed;

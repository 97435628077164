import React, { Component } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import LazyLoad from "react-lazy-load";

class ProjectSection extends Component {
  render() {
    const json = this.props.jsonData;
    const some_projects_title = json.acf.some_projects_title;
    const some_projects_description = json.acf.some_projects_description;
    //console.log(json.acf.some_projects);

    const projects = json.acf.some_projects.map((item, i) => (
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        key={i}
        className={
          "some-projects-blocks project-block" +
          i +
          " col-12 col-6-m no-padding relative flex flex-column"
        }
      >
        <div className="block-wrapper">
          <div className="projects-img-container">
            <LazyLoad debounce={false} offsetVertical={500}>
              <img
                className="fluid-img"
                src={item.image.sizes.projects_mobile}
                alt={item.image.title}
                srcSet={`${item.image.sizes.projects_mobile} 300w, ${
                  item.image.sizes.slider_tablet
                } 768w, ${item.image.sizes.projects} 1280w`}
              />
            </LazyLoad>
          </div>

          <div className="text-wrap">
            <span className="background-colour" />
            <span className="flex flex-grow-1" />
            <div className="text-wrapping">
              <h4 className="title">{item.title}</h4>
              <span className="visit-site">{item.description}</span>
            </div>
          </div>
        </div>
      </a>
    ));

    return (
      <React.Fragment>
        <ScrollableAnchor id={"projects"}>
          <section id="recent-jobs">
            <article className="container">
              <div>
                <h2 className="title">{some_projects_title}</h2>
              </div>
              <div
                className="description col-12 col-8-m center"
                dangerouslySetInnerHTML={{
                  __html: some_projects_description
                }}
              />
            </article>
          </section>
        </ScrollableAnchor>
        <section id="some-projects">
          <div className="flex flex-wrap container no-padding">{projects}</div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProjectSection;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import linkedin_logo from "../../assets/linkedin.svg";
class Footer extends Component {
  render() {
    return (
      <div className="mainfooter container">
        <div className="linkedin-logo block text-center">
          <a
            href="https://www.linkedin.com/in/stevefurley/"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-button"
          >
            <img
              src={linkedin_logo}
              alt="Linked in logo"
              className="inline-block"
            />
          </a>
        </div>
        <div className="block text-center copyright">
          <span className="copyright">&copy; Copyright 2024</span>
        </div>
        <div className="block">
          <Link className="link" to="/">
            Home
          </Link>
          <Link className="link" to="/terms-conditions">
            Terms &amp; Conditions
          </Link>
          <Link className="link" to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    );
  }
}

export default Footer;

import React, { Component } from "react";

import AboutMe from "../components/AboutMe/aboutme";
import SkillsUsed from "../components/SkillsUsed/skillsUsed";
import ProjectSection from "../components/Projects/projects";
import Testimonials from "../components/Testimonials/testimonials";
import Loader from "../components/Sitewide/loader";
import axios from "axios";
//import Services from "../components/Services/services";
class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: [],
      isToggleOn: false
    };
  }

  toggleShowModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  componentDidMount() {
    axios
      .get(
        "https://www.stevefurley.co.uk/wordpress_site/wp-json/wp/v2/pages/121"
      )
      .then(response => {
        this.setState(() => {
          return {
            data: response.data,
            loading: false
          };
        });
      });
  }
  render() {
    const { loading, data } = this.state;
    if (loading) {
      return <Loader />;
    }

    return (
      <section className="main-container" id="content">
        <AboutMe jsonData={this.state.data} />
        <Testimonials jsonData={this.state.data} />
        <ProjectSection jsonData={this.state.data} />

        <SkillsUsed jsonData={this.state.data} />
      </section>
    );
  }
}

export default HomePage;
